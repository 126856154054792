import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components'
import { Icon } from '@iconify/vue'

export default createVuetify({
  breakpoint: {
    mobileBreakpoint: 'xs'
  },
  icons: {
    defaultSet: 'iconify',
    sets: {
      iconify: { component: Icon }
    }
  },
  theme: {
    defaultTheme: 'dark',
    customProperties: true,
    themes: {
      light: {
        dark: false,
        variables: {},
        //TODO Type definition is wrong, remove once fixed
        // @ts-ignore
        colors: {
          primary: process.env.VUE_APP_THEME_PRIMARY ? process.env.VUE_APP_THEME_PRIMARY : '#3ea200',
          secondary: process.env.VUE_APP_THEME_SECONDARY ? process.env.VUE_APP_THEME_SECONDARY : '#363636',
          background: process.env.VUE_APP_THEME_SECONDARY ? process.env.VUE_APP_THEME_SECONDARY : '#363636',
          surface: process.env.VUE_APP_THEME_SECONDARY ? process.env.VUE_APP_THEME_SECONDARY : '#363636',
          accent: '#ef0000',
          text: '#000'
        }
      },
      dark: {
        dark: true,
        variables: {},
        //TODO Type definition is wrong, remove once fixed
        // @ts-ignore
        colors: {
          primary: process.env.VUE_APP_THEME_PRIMARY ? process.env.VUE_APP_THEME_PRIMARY : '#3ea200',
          secondary: process.env.VUE_APP_THEME_SECONDARY ? process.env.VUE_APP_THEME_SECONDARY : '#363636',
          background: process.env.VUE_APP_THEME_SECONDARY ? process.env.VUE_APP_THEME_SECONDARY : '#363636',
          surface: process.env.VUE_APP_THEME_SECONDARY ? process.env.VUE_APP_THEME_SECONDARY : '#363636',
          accent: '#ef0000',
          text: '#fff'
        }
      }
    }
  },
  components: {
    /*Note: vuetify-loader fails to properly handle the color of v-btn, so that one component needs to be manually loaded */
    VBtn
  },
})
