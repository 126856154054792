import { createRouter, createWebHistory } from 'vue-router'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/about',
      name: 'About',
      component: () => import('@/components/endpoints/About.vue')
    },
    {
      path: '/sources',
      name: 'Sources',
      component: () => import('@/components/endpoints/Sources.vue')
    },
    {
      path: '/',
      alias: '/lite/corona',
      name: 'default',
      component: () => import('@/components/endpoints/Default.vue')
    }
  ]
})
