import { InjectionKey } from 'vue'
import { createStore, Store, useStore } from 'vuex'
import { storeOptions, CoronaState } from '@/store/store'

export const key: InjectionKey<Store<CoronaState>> = Symbol()

export const store = createStore<CoronaState>(storeOptions)

export function useCoronaStore (): Store<CoronaState> {
  return useStore(key)
}