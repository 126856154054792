
import {computed, defineComponent, watch} from 'vue'
import {useI18n} from 'vue-i18n'
import {useCoronaStore} from '@/plugins/store'

export default defineComponent({
  name: 'App',
  components: {},

  setup() {
    const {locale} = useI18n()

    const store = useCoronaStore()

    let savedLocale: string | null = localStorage.getItem('locale')
    if (!savedLocale) {
      savedLocale = locale.value
    }
    store.commit('setLocale', savedLocale)
    locale.value = savedLocale
    const currentLocale = computed(() => store.getters.locale)
    watch(currentLocale, value => {
      //Set i18n Locale accordingly
      locale.value = value
    })

    return {}
  }
})
