import { CoronaAttributes } from '@/types'

export type CoronaStyle = {
  /** Name of the style, used as an identifier and for translations and UI */
  name: keyof CoronaAttributes,
  /** Associated MaterialDesignIcon */
  icon: string,
  /** Base color of the style, used for UI and graphs */
  color: string
  /**
   * A Mapbox scale interpolation array alternating between a value and it's associated color
   * @example
   * [0, '#ffffff', 50, '#888888', 50, '#000000',]
   * //Write this as the prettier version
   * [
   *   ...[0, '#ffffff'],
   *   ...[50, '#888888'],
   *   ...[50, '#000000'],
   * ]
   **/
  scale: Array<string | number>
  /**Which chart type to use. Available options are 'line' and 'bar' */
  chartType: 'line' | 'bar'
  /** Should the chart be a line chart or an area chart? */
  fillChart: boolean
  /** Should the chart use the normalized or absolute value? */
  relativeChart: boolean
  /** Should the tooltip show the normalized value */
  relativeTooltip: boolean
  /** */
  chartGroup: number
  /** Should the normalized value be displayed as a percentage instead of a "per 100k" value */
  relativeAsPercent: boolean
  /** In which order should the attribute appear in the tooltip on Desktop */
  tooltipOrder: number | null
  /** Should the number be rounded to a fixed amount of digits or to a precision instead*/
  formatMode: 'fixed' | 'precision'
  /** To how many digits should the number be formatted to (either based as fixed or precision)*/
  digits: number
  /**
   * Should the absolute value remain hidden in the CoronaNumericData table
   */
  hideAbsolute?: boolean
}

const QUARANTINE_ENABLED = Number(process.env.VUE_APP_SERVICE_QUARANTINE)
const HOSPITAL_ENABLED = Number(process.env.VUE_APP_SERVICE_HOSPITALDATA)
const VACCINE_ENABLED = Number(process.env.VUE_APP_SERVICE_VACCINATIONS)

const styles: Record<string, CoronaStyle> = {
  'incidence': {
    name: 'incidence',
    icon: 'mdi-chart-timeline-variant',
    color: '#F44336',
    scale: [
      ...[0, '#ffffff'],
      ...[100, '#ffdd00'],
      ...[500, '#ff2600'],
      ...[1000, '#b11000'],
      ...[2000, '#240000'],
      ...[4000, '#ff30fb'],
    ],
    fillChart: false,
    chartType: 'line',
    relativeChart: true,
    chartGroup: 0,
    relativeTooltip: true,
    relativeAsPercent: false,
    tooltipOrder: 1,
    formatMode: 'precision',
    digits: 3,
    hideAbsolute: true
  },
  'cases_new': {
    name: 'cases_new',
    icon: 'mdi-arrow-top-right',
    color: '#F44336',
    scale: [
      ...[0, '#ffffff'],
      ...[7.5, '#ffdd00'],
      ...[35, '#ff2600'],
      ...[75, '#b11000'],
      ...[150, '#240000'],
      ...[300, '#ff82fd'],
      ...[600, '#ff30fb'],
    ],
    fillChart: false,
    chartType: 'bar',
    relativeChart: false,
    chartGroup: 0,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: 2,
    formatMode: 'fixed',
    digits: 2
  },
  'cases_current': {
    name: 'cases_current',
    icon: 'mdi-virus',
    color: '#FF9800',
    scale: [
      ...[0, '#ffffff'],
      ...[100, '#ffdd00'],   //7.5 * 14,
      ...[500, '#ff2600'],   //35 * 14,
      ...[1000, '#b11000'],  //75 * 14,
      ...[2000, '#240000'],  //150 * 14,
      ...[4000, '#ff82fd'],  //300 * 14,
      ...[8000, '#ff30fb'],  //600 * 14,
    ],
    fillChart: false,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 0,
    relativeTooltip: false,
    relativeAsPercent: false,
    digits: 0,
    tooltipOrder: null,
    formatMode: 'fixed',
  },
  'cases_total': {
    name: 'cases_total',
    icon: 'mdi-virus',
    color: '#FF9800',
    scale: [
      ...[0, '#ffffff'],
      ...[5000, '#ffdd00'],
      ...[10000, '#ff2600'],
      ...[20000, '#b11000'],
      ...[30000, '#240000'],
      ...[40000, '#ff30fb'],
    ],
    fillChart: true,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 0,
    relativeTooltip: true,
    relativeAsPercent: true,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'quarantine_current': {
    name: 'quarantine_current',
    icon: 'mdi-home-account',
    color: '#FBC02D',
    scale: [
      ...[0, '#FFFFFF'],
      ...[500, '#FFF176'],
      ...[1000, '#FDD835'],
      ...[2000, '#F9A825'],
      ...[5000, '#F57F17'],
      ...[7500, '#d74708'],
    ],
    fillChart: false,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 1,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'quarantine_total': {
    name: 'quarantine_total',
    icon: 'mdi-home-account',
    color: '#FBC02D',
    scale: [
      ...[0, '#FFFFFF'],
      ...[5000, '#FFF176'],
      ...[10000, '#FDD835'],
      ...[20000, '#F9A825'],
      ...[30000, '#F57F17'],
      ...[40000, '#d74708'],
    ],
    fillChart: true,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 1,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'deaths_new': {
    name: 'deaths_new',
    icon: 'mdi-grave-stone',
    color: '#8c8c8c',
    scale: [
      ...[0, '#ffffff'],
      ...[4, '#999999'],
      ...[8, '#333333'],
      ...[12, '#000000'],
    ],
    fillChart: false,
    chartType: 'bar',
    relativeChart: false,
    relativeTooltip: false,
    chartGroup: 2,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'deaths_total': {
    name: 'deaths_total',
    icon: 'mdi-grave-stone',
    color: '#8c8c8c',
    scale: [
      ...[0, '#ffffff'],
      ...[75, '#999999'],
      ...[150, '#333333'],
      ...[300, '#000000']
    ],
    fillChart: true,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 2,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'healed_new': {
    name: 'healed_new',
    icon: 'healthicons:health',
    color: '#4CAF50',
    scale: [
      ...[0, '#ffffff'],
      ...[25, '#7aff4a'],
      ...[75, '#41e000'],
      ...[150, '#11a101'],
      ...[300, '#184501'],
    ],
    fillChart: false,
    chartType: 'bar',
    relativeChart: false,
    chartGroup: 2,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'healed_total': {
    name: 'healed_total',
    icon: 'healthicons:health',
    color: '#4CAF50',
    scale: [
      ...[0, '#ffffff'],
      ...[2500, '#7aff4a'],
      ...[5000, '#41e000'],
      ...[10000, '#11a101'],
      ...[50000, '#184501'],
    ],
    fillChart: true,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 2,
    relativeTooltip: true,
    relativeAsPercent: true,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 2,
  },
  'hospital_current': {
    name: 'hospital_current',
    icon: 'mdi-bed',
    color: '#30c49f',
    scale: [
      ...[0, '#43A047'],
      ...[25, '#ffdd00'],
      ...[50, '#ff7700'],
      ...[75, '#b11000'],
      ...[100, '#240000'],
    ],
    fillChart: false,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 3,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'critical_current': {
    name: 'critical_current',
    icon: 'mdi-car-brake-alert',
    color: '#30c49f',
    scale: [
      ...[0, '#43A047'],
      ...[3, '#ffdd00'],
      ...[6, '#ff7700'],
      ...[9, '#b11000'],
      ...[12, '#240000'],
    ],
    fillChart: false,
    chartType: 'line',
    relativeChart: false,
    chartGroup: 3,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'tests_new': {
    name: 'tests_new',
    icon: 'mdi-test-tube',
    color: '#1E88E5',
    scale: [
      ...[0, '#ffffff'],
      ...[100, '#a4d6ff'],
      ...[300, '#49aaff'],
      ...[750, '#0070cd'],
      ...[1500, '#001f8b'],
    ],
    fillChart: false,
    chartType: 'bar',
    relativeChart: false,
    chartGroup: 4,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'tests_total': {
    name: 'tests_total',
    icon: 'mdi-test-tube',
    color: '#1E88E5',
    scale: [
      ...[0, '#ffffff'],
      ...[2000, '#a4d6ff'],
      ...[70000, '#49aaff'],
      ...[150000, '#0070cd'],
      ...[250000, '#001f8b'],
    ],
    fillChart: true,
    chartGroup: 4,
    chartType: 'line',
    relativeChart: false,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 0,
  },
  'vaccine_first_dosage_new': {
    name: 'vaccine_first_dosage_new',
    icon: 'mdi-needle',
    color: '#5849e7',
    scale: [
      ...[0, '#ffffff'],
      ...[100, '#c4adf0'],
      ...[250, '#8c73bd'],
      ...[500, '#59369c'],
      ...[750, '#372063'],
      ...[1000, '#21123d'],
    ],
    fillChart: false,
    chartGroup: 5,
    chartType: 'bar',
    relativeChart: false,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    digits: 0,
    formatMode: 'fixed',
  },
  'vaccine_first_dosage_total': {
    name: 'vaccine_first_dosage_total',
    icon: 'ic:baseline-vaccines',
    color: '#5849e7',
    scale: [
      ...[0, '#ff0000'],
      ...[40000, '#ff6f00'],
      ...[50000, '#ffff00'],
      ...[60000, '#c0ff00'],
      ...[70000, '#63ff00'],
      ...[80000, '#166500'],
    ],
    fillChart: true,
    chartGroup: 5,
    chartType: 'line',
    relativeChart: false,
    relativeTooltip: true,
    relativeAsPercent: true,
    tooltipOrder: null,
    digits: 2,
    formatMode: 'fixed',
  },
  'vaccine_second_dosage_new': {
    name: 'vaccine_second_dosage_new',
    icon: 'mdi-needle',
    color: '#673AB7',
    scale: [
      ...[0, '#ffffff'],
      ...[100, '#c4adf0'],
      ...[250, '#8c73bd'],
      ...[500, '#59369c'],
      ...[750, '#372063'],
      ...[1000, '#21123d'],
    ],
    fillChart: false,
    chartGroup: 5,
    chartType: 'bar',
    relativeChart: false,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    digits: 0,
    formatMode: 'fixed',
  },
  'vaccine_second_dosage_total': {
    name: 'vaccine_second_dosage_total',
    icon: 'ic:baseline-vaccines',
    color: '#673AB7',
    scale: [
      ...[0, '#ff0000'],
      ...[50000, '#ff6f00'],
      ...[60000, '#ffff00'],
      ...[65000, '#c0ff00'],
      ...[70000, '#63ff00'],
      ...[80000, '#166500'],
    ],
    fillChart: true,
    chartGroup: 5,
    chartType: 'line',
    relativeChart: false,
    relativeTooltip: true,
    relativeAsPercent: true,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 2
  },
  'vaccine_booster_dosage_new': {
    name: 'vaccine_booster_dosage_new',
    icon: 'mdi-needle',
    color: '#8f3ab7',
    scale: [
      ...[0, '#ffffff'],
      ...[100, '#c4adf0'],
      ...[250, '#8c73bd'],
      ...[500, '#59369c'],
      ...[750, '#372063'],
      ...[1000, '#21123d'],
    ],
    fillChart: false,
    chartGroup: 5,
    chartType: 'bar',
    relativeChart: false,
    relativeTooltip: false,
    relativeAsPercent: false,
    tooltipOrder: null,
    digits: 0,
    formatMode: 'fixed',
  },
  'vaccine_booster_dosage_total': {
    name: 'vaccine_booster_dosage_total',
    icon: 'ic:baseline-vaccines',
    color: '#8f3ab7',
    scale: [
      ...[0, '#ff0000'],
      ...[25000, '#ff6f00'],
      ...[50000, '#ffff00'],
      ...[60000, '#c0ff00'],
      ...[70000, '#63ff00'],
      ...[80000, '#166500'],
    ],
    fillChart: true,
    chartGroup: 5,
    chartType: 'line',
    relativeChart: false,
    relativeTooltip: true,
    relativeAsPercent: true,
    tooltipOrder: null,
    formatMode: 'fixed',
    digits: 2
  }
}
if (QUARANTINE_ENABLED !== 1) {
  delete styles['quarantine_current']
  delete styles['quarantine_total']
}
if (HOSPITAL_ENABLED !== 1) {
  delete styles['hospital_current']
  delete styles['critical_current']
}
if (VACCINE_ENABLED !== 1) {
  delete styles['vaccine_first_dosage_new']
  delete styles['vaccine_first_dosage_total']
  delete styles['vaccine_second_dosage_new']
  delete styles['vaccine_second_dosage_total']
  delete styles['vaccine_booster_dosage_new']
  delete styles['vaccine_booster_dosage_total']
}

export default styles