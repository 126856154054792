import { StoreOptions } from 'vuex'
import API, { CoronaDataQuery } from '@/util/api'
import isEqual from 'lodash/isEqual'
import styles, { CoronaStyle } from '@/util/stlyes'
import type { CoronaData, CoronaDataStorage } from '@/types'

export type CoronaLocale = 'de' | 'it' | 'en'

export type CoronaState = {
  loading: boolean
  executedQueries: Partial<CoronaDataQuery>[]
  data: CoronaDataStorage
  locale: CoronaLocale
  languages: Record<CoronaLocale, { language: string, locale: CoronaLocale }>
}

export const storeOptions: StoreOptions<CoronaState> = {
  state: {
    loading: false,
    executedQueries: [],
    data: {},
    locale: process.env.VUE_APP_I18N_LOCALE as CoronaLocale,
    languages: {
      'de': {language: 'Deutsch', locale: 'de'},
      'it': {language: 'Italiano', locale: 'it'},
      'en': {language: 'English', locale: 'en'}
    },
  },
  mutations: {
    setLoading (state, loading: boolean) {
      state.loading = loading
    },
    /** Merges all new data into the state */
    mergeData (state, data: CoronaDataStorage) {
      for (const day of Object.keys(data)) {
        if (!state.data[day]) {
          //No data present for this day, just place the whole day into the state
          state.data[day] = data[day]
        } else {
          //Merge area entries into existing ones
          state.data[day] = Object.assign({}, state.data[day], data[day])
        }
      }
    },
    setLocale (state, locale: CoronaLocale) {
      state.locale = locale
      localStorage.setItem('locale', state.locale)
    },
  },
  actions: {
    async loadData ({ state, commit }, query: Partial<CoronaDataQuery>) {
      //Check if this query has already been executed to avoid duplicate calls
      if (state.executedQueries.find(q => isEqual(q, query))) {
        return
      } else {
        //Remember that this query has already been executed
        state.executedQueries.push(Object.assign({}, query))
        const data = await API.getData(query)
        commit('mergeData', data)
      }
    },
    async logEvent (state, event: {event: string, value: string}) {
      await API.logEvent({
        host: window.location.hostname,
        event: event.event,
        value: event.value
      })
    }
  },
  getters: {
    loading: (state): boolean => state.loading,
    locale: (state): CoronaLocale => state.locale,
    languages: (state) => state.languages,
    data: (state): CoronaDataStorage => state.data,
    dataForArea: (state) => (area: string) => {
      const ret: Record<string, Partial<CoronaData> | null> = {}
      for (const day of Object.keys(state.data)) {
        if (state.data[day][area]) {
          ret[day] = state.data[day][area]
        } else {
          ret[day] = null
        }
      }
      return ret
    },
    stylesForFeature: (state, getters) => (area: string, date?: number): CoronaStyle[] => {
      const ret: CoronaStyle[] = []
      for (const style of Object.values(styles)) {
        if (date) {
          if (state.data?.[date]?.[area]?.[style.name]) {
            ret.push(style)
          }
        } else {
          if (
            Object.values(getters.dataForArea(area) as Record<string, Partial<CoronaData> | null>)
              .find(entry => entry?.[style.name])
          ) {
            ret.push(style)
          }
        }
      }
      return ret
    }
  }
}
