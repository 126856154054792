import { createI18n } from 'vue-i18n'

import de from '@/locales/de.json'
import it from '@/locales/it.json'
import en from '@/locales/en.json'

export default createI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    de,
    it,
    en
  }
})