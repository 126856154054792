import axios from 'axios'
import type { Feature, FeatureCollection } from 'geojson'
import type { CoronaDataStorage } from '@/types'

export interface AdminAreaQuery {
  resolution: '100' | '10' | '5' | '2' | '1' | '0_1'
  parent: string
  ids: number[]
  prop: Record<string, string>
}

export interface CoronaDataQuery {
  as_object: string
  parent: string
  adminLevel: string
  areas: string
  date: string
  date_start: string
  date_end: string
}

export interface AnalyticsEvent {
  host: string
  event: string
  value: string
}

export default class API {
  static getAPIUrl (endpoint: string, query?: Record<string, string | string[]>): string {
    let url
    if (endpoint.includes('features')) {
      url = `${process.env.VUE_APP_GEOMETRY_URL}${process.env.VUE_APP_GEOMETRY_API}`
    } else {
      url = `${process.env.VUE_APP_BACKEND_URL}${process.env.VUE_APP_BACKEND_API}`
    }
    url = url + endpoint
    if (!url.endsWith('/')) {
      console.warn('Appending trailing backslash automatically, but this should be part of the endpoint!')
      url += '/'
    }
    if (!query) {
      query = {}
    }
    if (Number(process.env.VUE_APP_DEV) == 1) {
      query['nocache'] = '1'
    }
    const tuples = []
    for (const key of Object.keys(query)) {
      if (Array.isArray(query[key])) {
        (query[key] as string[]).forEach(val => tuples.push([key, val]))
      } else {
        tuples.push([key, query[key] as string])
      }
    }
    const searchParams = new URLSearchParams(tuples)
    const querystring = searchParams.toString()
    if (querystring.length > 0) {
      url = `${url}?${querystring}`
    }
    return url
  }

  static async get (endpoint: string, query?: Record<string, string | string[]>): Promise<unknown> {
    const url = this.getAPIUrl(endpoint, query)
    return axios.get(url)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  }

  static async post (endpoint: string, data: unknown): Promise<unknown> {
    const url = this.getAPIUrl(endpoint)
    return axios.post(url, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  }

  static async put (endpoint: string, data: unknown): Promise<unknown> {
    const url = this.getAPIUrl(endpoint)
    return axios.put(url, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  }

  static async del (endpoint: string): Promise<unknown> {
    const url = this.getAPIUrl(endpoint)
    return axios.put(url)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  }

  static convertFeatureQuery (query: Partial<AdminAreaQuery>): Record<string, string | string[]> {
    const q: Record<string, string | string[]> = {}
    if (query.ids)
      q.ids = query.ids.join(',')
    if (query.resolution)
      q.resolution = query.resolution
    if (query.parent)
      q.parent = query.parent
    if (query.prop)
      q.prop = Object.entries(query.prop).map(entry => `${entry[0]};${entry[1]}`)
    return q
  }

  static featureQueryToDataQuery (query: Partial<AdminAreaQuery>): Partial<CoronaDataQuery> {
    const dataQuery: Partial<CoronaDataQuery> = {}
    if (query.parent) {
      dataQuery.parent = query.parent
    }
    if (query.ids) {
      dataQuery.areas = query.ids.join(',')
    }
    if (query.prop?.['admin_level']) {
      dataQuery.adminLevel = query.prop['admin_level']
    }
    return dataQuery
  }

  static async getFeature (id: string): Promise<Feature> {
    return await this.get(`features/${id}/`, { resolution: '0_1' }) as Promise<Feature>
  }

  static async getFeatureList (query: Partial<AdminAreaQuery>): Promise<FeatureCollection> {
    return await this.get('features/', this.convertFeatureQuery(query)) as Promise<FeatureCollection>
  }

  static async getLatestDate (query: Partial<AdminAreaQuery>): Promise<string> {
    return await this.get('data/latest/', this.featureQueryToDataQuery(query)) as Promise<string>
  }

  static async getFirstDate (query: Partial<AdminAreaQuery>): Promise<string> {
    return await this.get('data/earliest/', this.featureQueryToDataQuery(query)) as Promise<string>
  }

  static async getData (query: Partial<CoronaDataQuery>): Promise<CoronaDataStorage> {
    query.as_object = '1'
    return await this.get('data/', query) as Promise<CoronaDataStorage>
  }

  static async logEvent (data: AnalyticsEvent): Promise<void> {
    await this.post('analytics', data)
  }
}