import { createApp } from 'vue'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'
import { key, store } from '@/plugins/store'
import vuetify from '@/plugins/vuetify'

import App from '@/App.vue'

/** Chart.js 3.x Registration in order to allow tree shaking */
import { Chart, LinearScale, LineController, BarController, BarElement, LineElement, PointElement, TimeScale, Tooltip, Legend, Filler } from 'chart.js'
import 'chartjs-adapter-date-fns'

Chart.register(
  LineController, BarController,
  PointElement, LineElement, BarElement,
  LinearScale, TimeScale,
  Tooltip, Legend, Filler
)

const app = createApp(App)

app.use(i18n)
app.use(router)
app.use(store, key)
app.use(vuetify)

app.mount('#app')
